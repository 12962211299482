<template>
<div class="list" id="webview">
     <van-list
        v-model="loading"
        :finished="finished"
        :offset="offset"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <div class="show-list">
        <div class="list-item flex-start" style="justify-content:space-between;height:85.5px;" v-for="(item,index) in listForm"  :key="index" @click="goToUrl(item)">
            <img :src="item.face" alt="图片" class="list-img"  v-if="item.face !=''">
            <div class="list-msg" :style="item.face == ''?'width:310px':'width:240px'" v-if="item.title !=''">
              <div class="list-title">{{item.title}}</div>
              <div class="list-tips fz12">{{item.introduce}}</div>
            </div>
            <div class="lin"></div>
        </div>
        <!-- <div v-if="showList" style="text-align:center;color:#CBCDD0;margin-bottom:5px;padding-bottom:15px;display:flex;justify-content:center;">
          <div >--</div>
          <div style="margin:0 10px;">已经到底了</div>
          <div>--</div>
        </div> -->
      </div>
    </van-list>
  </div>
</template>
<script>
import {checkInfoModel} from '@/api/workbench';
import {getUploadToken} from '@/api/upload'
export default {
  name: "webView2",
  data(){
    return{
      listForm:[],
      type:1,
      webViewForm: {
        id: '',
        face: '',
        wv_name: '',
        remarks: '',
        init_data: [],
        wv_type: '',
      },
      page:1,// 当前页码
      showList:false,
      judge:true,
      loading:false,
      finished:false, // 滚动加载完成
      pageSize: 10, // 分页大小
      total: 0, // 查询总条数
      offset:0,//滚动条与底部距离小于 offset 时触发load事件，默认300
    }
  },
  created() {
    this.initFunction()
  },
  methods:{
    initFunction(){
        let appid=this.GetQueryString('appid');
        let id = this.GetQueryString('id')
        let api_access_token =  this.GetQueryString('api_access_token')
        let userid = this.GetQueryString('userid')
        let is_page = this.GetQueryString('is_page')
        this.initModel(appid,id,api_access_token,userid)
        // 
        // if(is_page == 1 && this.judge == true){
           
        // }
    },
    GetQueryString(name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        let r = window.location.hash.substr(1).match(reg); 
        if (r != null) return unescape(r[2]); return null; 
    },
    initModel(appid,id,api_access_token,userid){
      this.loading =  true
      checkInfoModel({wv_type:'list',appid:appid,id:id,api_access_token:api_access_token,userid:userid,page:this.page,limit:this.pageSize}).then(res=>{
        res.data.init_data.forEach(item=>{
          this.listForm.push(item)
        })
        if(res.data.init_data.length == 10){
              this.finished = false;
          }else{
              this.finished = true;
        }
        this.loading =  false
      })
    },
    goToUrl(item){
      if(item.is_jump == 1){
         window.open(item.jump_url)
      }
    },
    // 滚动底部加载数据
    onLoad () {
        this.loading = true;
        this.page = this.page + 1
        this.initFunction()
        // if (scrollTop + clientHeight >= scrollHeight) { // 滚动到底部，逻辑代码
        //     if(this.judge == true){
        //        
        //         this.initFunction()
        //     }else{
        //       this.webview.removeEventListener('scroll', this.lazyLoading)
        //       this.showList = true
        //     }
        //   }
      }
  }
}
</script>

<style lang="less" scoped>
.list{
   height:100%;
   background:#F5F5F5;
  .show-list{
    // height: 140px;
    width: 100%;
    background: #fff;
    .list-item{
      padding: 8px 20px;
      border-bottom:1px solid #F0F0F0;
      .list-img{
        width:78px;
        height: 58px;
        overflow:hidden;
        // margin-left: 15px;
      }
      .list-msg{
        margin-left:5px;
        width:240px;
        height:78.5px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        .list-tips,.list-title{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color:#333333;
          font-size: 14px;
          // font-weight:500;
        }
        .list-title{
          margin-bottom: 5px;
          color:#333333;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
  }

}
</style>